import { ContactMeButton } from "./ContactMeButton";

export function Footer() {
    return (
        <div className="bg-gray-800 text-white w-full h-48">
            <div>
                <ContactMeButton/>
            </div>
            Alexandre Spitz 2022 - 2023
        </div>

    )
}