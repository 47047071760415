import React from "react"
export function ServiceCard({ title, description, icon, href } : { title: string, description: string, icon: string, href: string }) {
    return (
        <div className="flex flex-col items-center justify-center w-64 h-64 bg-white rounded-lg shadow-lg m-4 p-2 hover:shadow-xl transition-all cursor-pointer" onClick={
            () => {
                // Link to the href same page no reload
                window.location.href = href;
            }
        }>
            <div className="flex items-center justify-center w-16 h-16 mt-5 bg-indigo-500 rounded-full">
                <img src={icon} alt="icon" className="w-8 h-8" />
            </div>
            <div className="mt-6">
                <h1 className="text-xl font-semibold text-gray-700">{title}</h1>
                <p className="mt-2 text-sm text-gray-500">{description}</p>
            </div>
        </div>


    )
}