export function ContactMeButton() {
    return (
        <a href="https://share-eu1.hsforms.com/1RIDs85riQpKGqBgtC5M9kwfuaz0" target={"_blank"} rel="noreferrer">
        <button className="
        bg-indigo-500
        hover:bg-indigo-700
        text-white
        font-bold
        py-2
        px-4
        rounded
        transition-all
        m-4
        "
       >
            <div className="flex">
                <img src="https://img.icons8.com/ios/35/FFFFFF/mail.png" alt="mail icon" className="w-6 h-6 mr-2" />
                <span> Get in touch !
                </span>
            </div>

        </button>
        </a>
    )

}