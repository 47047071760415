import './App.css';
import { ServiceCard } from './views/ServiceCard';
import { ServiceSection } from './views/ServiceSection';
import { GetAQuoteButton } from './views/GetAQuoteButton';
import { LinkedInButton } from './views/LinkedInButton';
import { ContactMeButton } from './views/ContactMeButton';
import { Footer } from './views/Footer';

function App() {

  return (
    <div className="App pt-8 scroll-smooth">
      <h1 className='text-3xl font-bold'>Alexandre Spitz</h1>
      <h2 className='text-2xl text-gray-600'>Freelancer as Fullstack Software Engineer & Lead Tech</h2>
      {/* Introduction paragraph */}
      <div className='mt-24'>
        <h3 className='text-2xl mb-12'>Disponibility: <span className='text-green-500 p-4 m-2 bg-white rounded'>Part-time</span></h3>
        <h3 className='text-2xl'>Location: <span className='text-green-500 p-4 m-2 bg-white rounded'>Remote</span> <span className='text-orange-500 p-4 m-2 bg-white rounded'>Paris</span></h3>

      </div>
      <div className='h-[90vh] pt-[10vh]'>
        <div className='md:w-3/5 p-8 shadow-lg m-auto bg-white'>
          <p className='text-lg'>
            Hey ! <br />
            I'm a software engineer and tech lead with experience on multiple software engineering fields. <br />
            During my career I've worked with multiple companies, from small to big, from startups to big corporations. <br />
            I've worked on projects of many sizes, from tens of users to hundred of thousands. <br />
            Working on project where security and reliability is key doesn't scare me. <br />
            You will be able to find more information about my experience on my LinkedIn profile. <br />
            <LinkedInButton /> <ContactMeButton />

          </p>
        </div>
        {/* Arrow to see more */}
        <div className='flex justify-center mt-24'>
          <a href='#services-card'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 animate-bounce" viewBox="0 0 20 20" fill="#2E5475">
              <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v10.586l-3.293-3.293a1 1 0 10-1.414 1.414l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414L11 15.586V4a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </a>
        </div>
      </div>


      {/* Flex aligned center on 3/5 width for big screens */}
      <div className='flex  items-center justify-center md:w-3/5 mx-auto flex-wrap' id='services-card'>
        {/* Lead tech */}
        <ServiceCard href="#lead" title='Lead Tech' icon='https://img.icons8.com/ios/50/FFFFFF/omnichannel.png' description='Technological Lead (quality control, decisions, team lead, etc.)' />
        <ServiceCard href='#web_dev' title='Web Development' description='I can build you a website or web app from scratch or help you with your existing project.' icon='https://img.icons8.com/ios/50/FFFFFF/web-design.png' />
        <ServiceCard href='#devops' title='DevOps' description='I can help you with your DevOps needs, from setting up your CI/CD pipeline to deploying your app.' icon='https://img.icons8.com/ios/50/FFFFFF/devops.png' />
      </div>
      <div className='flex flex-wrap m-auto justify-around md:w-2/5'>
        <GetAQuoteButton />

      </div>
      {/* Title with lines on side */}
      <hr className="my-4 mx-auto w-48 h-1 bg-gray-200 rounded border-0 md:my-10 dark:bg-gray-700" />
      <h3 className='text-4xl mt-8 mb-6'>My services</h3>
      <ServiceSection id='lead' title='Tech Lead' icon='https://img.icons8.com/ios/150/2E5475/omnichannel.png'>
        <p className='text-left text-gray-600'>
          I can help you as a Tech Lead in your project. Here are the different topics I will be able to help you with:
          <div className='ml-4'>
            <li>Architecture & Technical Conception</li>
            <li>Quality control
              <ul className='ml-4'>
                <li>Code review</li>
                <li>Unit testing</li>
                <li>Integration testing</li>
                <li>End-to-end testing</li>
                <li>Automated Quality Gates (Sonar, etc.)</li>
              </ul>
            </li>
            <li>Team lead</li>
            <li>Decision making
              <ul className='ml-4'>
                <li>Technical decisions</li>
                <li>Project management</li>
              </ul>

            </li>
          </div>

          I have experience as a Tech Lead in a company of 160 people, working with a team of 2 to 7 people.

        </p>

      </ServiceSection>
      <ServiceSection id='web_dev' title='Web Development' icon='https://img.icons8.com/ios/150/2E5475/web-design.png'>
        <p className='text-left text-gray-600'>
          For a few years now, I have been developing web applications using the latest technologies. I have experience with both frontend and backend development, and I am always looking for new challenges.
          <br />
          I do make it essential to undertand the needs of my clients, and I always try to deliver the best possible solution.
          <br />
          My technical stack is large, but here are some of the technologies I use the most:
          <div className='ml-4'>
            <li>Frontend
              <ul className='ml-4 flex '>
                <li>React</li>
                <li>Angular</li>
                <li>Vue</li>
                <li>Tailwind</li>
                <li>Bootstrap</li>
              </ul>
            </li>
            <li>Backend
              <ul className='ml-4 flex '>
                <li>NodeJS</li>
                <li>Java</li>
                <li>C# ASP.Net Core</li>
              </ul>
            </li>
            <li>Database
              <ul className='ml-4 flex '>
                <li>Oracle DB</li>
                <li>SQLServer</li>
                <li>PostgreSQL</li>
                <li>MySQL</li>
                <li>MongoDB</li>
              </ul>
            </li>
          </div>


          <br />
          My musts are :
          <ul className='ml-4 ml-8 flex '>
            <li>Accessibility</li>
            <li>Code quality</li>
            <li>Security</li>
            <li>Performance</li>
            <li>Scalability</li>
          </ul>
        </p>
      </ServiceSection>
      <ServiceSection id='devops' title='DevOps' icon='https://img.icons8.com/ios/150/2E5475/devops.png'>
        <p className='text-left text-gray-600'>
          I have experience with DevOps, and I can help you with your DevOps needs.
          <br />
          I will be able to help you with Continuous Integration and Continuous Delivery, as well as with deploying your app on main platforms (Jenkins, CircleCi, Gitlab, Github, Nexus, AWS, etc.).
          <br />
          Because a Dockerized applications is a must nowadays, I can also help you with Dockerizing your app.
        </p>
      </ServiceSection>
      <div className='mb-8'>
        <hr className="my-4 mx-auto w-48 h-1 bg-gray-200 rounded border-0 md:my-10 dark:bg-gray-700" />
        <h3 className='text-4xl mt-8 mb-6'>Fares</h3>
        <div className='md:w-3/5 p-8 shadow-lg m-auto bg-white'>
          <p className='text-lg'>Practicing fair servicing pricing is important to me. Here are base prices for different services (negociable).</p>
          <table className='table-auto w-full'>
            <thead>
              <tr>
                <th className='px-4 py-2'>Service</th>
                <th className='px-4 py-2'>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='border px-4 py-2'>Lead Tech</td>
                <td className='border px-4 py-2'>525€/day*</td>
              </tr>
              <tr className='bg-gray-100'>
                <td className='border px-4 py-2'>Web Development</td>
                <td className='border px-4 py-2'>425€/day*</td>
              </tr>
              <tr>
                <td className='border px-4 py-2'>DevOps</td>
                <td className='border px-4 py-2'>475€/day*</td>
              </tr>
            </tbody>
          </table>
          * Days are 7 hours long
          <h4 className='text-2xl mt-4'>Contract basics</h4>
          <p>The following table is not contractual. Though, these are basics I use in contracts.</p>
          <table className='table-auto w-full'>
            <tbody>
              <tr>
                <td className='border px-4 py-2'>Code Quality</td>
                <td className='border px-4 py-2 w-2/3'>In Web Development services, code quality will be guarenteed based on the project.</td>
              </tr>
              <tr className='bg-gray-100'>
                <td className='border px-4 py-2'>Guarentee</td>
                <td className='border px-4 py-2'>A service asked and signed will be delivered or not paid. Bugfixes can occur on the same contract up to 1 month after the delivery.</td>
              </tr>
              <tr>
                <td className='border px-4 py-2'>Delivery</td>
                <td className='border px-4 py-2'>The delivery in pre-production is included in the base contract.</td>
              </tr>
              <tr className='bg-gray-100'>
                <td className='border px-4 py-2'>Payment</td>
                <td className='border px-4 py-2'>The payment is done in 3 parts : 30% at the beginning of the contract, and 40% at the delivery, 30% when bugs are fixed if applicable.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
