import { GetAQuoteButton } from "./GetAQuoteButton";

export function ServiceSection({ title, icon, children, id }: { title: string, icon: string, children: React.ReactNode, id: string }) {
    return (
        <div className="md:w-3/5 m-auto shadow-lg mb-8 pb-8 bg-white pt-4" id={id}>
            <div className="flex items-center p-2 flex-wrap">
                <div className="w-5/5 md:w-1/5 m-auto">
                <img src={icon} alt="icon"
                    className="w-32 h-32 mr-4 place-self-center md:place-self-start align-center" />
                </div>
                
                <p className="md:w-4/5">
                    <h1 className="text-left text-2xl font-semibold text-gray-700">{title}</h1>
                    <p className="text-lg">
                        {children}

                    </p>
                </p>
            </div>
            <GetAQuoteButton/>
        </div>
    )
}